import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../devices.service';
import { LanguageService } from '../language-service.service';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.scss']
})
export class SchedulesComponent implements OnInit {
  schedules:any;

  constructor(public deviceServ:DevicesService, public langServ:LanguageService) { }

  ngOnInit() {

  }
  
  returnFactoredSchedules(){
    let schedules = [];
    let allSchedules = this.deviceServ.returnSchedules();
    for(let i = 0; i < allSchedules.length ;i++){
      if(schedules.filter(schedule => schedule.unique == allSchedules[i].unique).length > 0){
        schedules.filter(schedule => schedule.unique == allSchedules[i].unique)[0].total += 1;
      } else {
        schedules.push({ type:allSchedules[i].selectType, unique:allSchedules[i].unique, total:1, expanded:false });
      }
    }
    return schedules;
  }

  returnScheduleName(schedule){
    if(schedule.type == 1){
      return this.deviceServ.returnDevices().filter(device => device.serial == schedule.unique)[0].name;
    } else if(schedule.type == 2) {
      return this.deviceServ.returnRooms().filter(room => room.uid == schedule.unique)[0].name;
    }
  }

  returnCurrentSchedules(schedule) {
    return this.deviceServ.returnSchedules().filter(sch => sch.unique == schedule.unique);
  }

  returnCurrentScheduleTime(schedule) {
    if(schedule.timeType == 1) {
      let dt = new Date(parseInt(schedule.time) * 1000);
      return this.returnPadZero(dt.getHours(), 2) + ":" + this.returnPadZero(dt.getMinutes(), 2);
    } else {
      let dt = new Date();
      let time_split = schedule.time.split(":");
      let time_new = parseInt(time_split[0]) + ((dt.getTimezoneOffset() / 60) * -1);
      return this.returnPadZero(time_new, 2) + ":" + time_split[1];
    }
  }

  returnCurrentScheduleAction(schedule){
    let action_split = schedule.action.split("*");
    return action_split[0];
  }

  returnCurrentScheduleTemp(schedule) {
    let action_split = schedule.action.split("*");
    return action_split[1];
  }

  returnCurrentScheduleMode(schedule) {
    let action_split = schedule.action.split("*");
    return action_split[2];
  }

  returnPadZero(num, size ) {
    if (num.toString().length >= size) return num;
    return ( Math.pow( 10, size ) + Math.floor(num) ).toString().substring( 1 );
  }

}
