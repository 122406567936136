<div class="web-header">
  <span class="title">Сценарии</span>
  <!-- <div class="header-btn left"></div> -->
  <!-- <div class="header-btn right add-ic"></div> -->
</div>
<div class="content-holder">
  <div class="room-row" [ngClass]="{ 'expanded':schedule.expanded, 'no-room':schedule.type == 1 }" *ngFor="let schedule of deviceServ.returnFactoredSchedules()">
    <div class="icon-holder" *ngIf="schedule.type == 2" (click)="presentViewSchedule(schedule.unique, schedule.type, schedule.total)">
      <i class="icon {{deviceServ.returnRoom(schedule.unique).type}}"></i>
    </div>
    <div class="info-holder" (click)="presentViewSchedule(schedule.unique, schedule.type, schedule.total)">
      <span class="name">{{ returnScheduleName(schedule) }}</span>
      <span class="mode">{{ schedule.total }} {{ langServ.returnTranslate('label_schedules') }}</span>
    </div>
    <!-- <div class="more"></div> -->
    <div class="details-btn" [ngClass]="{ 'expanded':schedule.expanded }" (click)="schedule.expanded = !schedule.expanded">{{ langServ.returnTranslate('label_quick_details') }} <i class='icon arrow-down'></i></div>
    <div class="device-list" *ngIf="schedule.expanded">
      <div class="device-row" *ngFor="let subschedule of returnCurrentSchedules(schedule)">
        <span class="device-name" style="width:50px;">{{ returnCurrentScheduleTime(subschedule) }}</span>
        <div class="small-days" *ngIf="subschedule.timeType == '2'">
          <span [ngClass]="{ 'active':subschedule.days[1] == '1' }">M</span>
          <span [ngClass]="{ 'active':subschedule.days[2] == '1' }">T</span>
          <span [ngClass]="{ 'active':subschedule.days[3] == '1' }">W</span>
          <span [ngClass]="{ 'active':subschedule.days[4] == '1' }">T</span>
          <span [ngClass]="{ 'active':subschedule.days[5] == '1' }">F</span>
          <span [ngClass]="{ 'active':subschedule.days[6] == '1' }">S</span>
          <span [ngClass]="{ 'active':subschedule.days[0] == '1' }">S</span>
        </div>
        <div class="power-btn in-row on" style="float:right;margin-right:15px;margin-bottom:5px;" *ngIf="returnCurrentScheduleAction(subschedule) == 'off'"><div class="inner"><i class="icon power"></i></div></div>
        <div class="device-mode" *ngIf="returnCurrentScheduleAction(subschedule) == 'set'">
          <div class="icon cold" *ngIf="returnCurrentScheduleMode(subschedule) == '1'"></div>
          <div class="icon hot" *ngIf="returnCurrentScheduleMode(subschedule) == '4'"></div>
          <div class="icon fan" *ngIf="returnCurrentScheduleMode(subschedule) == '3'"></div>
          <div class="icon dry" *ngIf="returnCurrentScheduleMode(subschedule) == '2'"></div>
          <div class="icon auto" *ngIf="returnCurrentScheduleMode(subschedule) == '0'"></div>
        </div>
        <span class="device-temp" *ngIf="returnCurrentScheduleAction(subschedule) == 'set' && returnCurrentScheduleMode(subschedule) == '1' || returnCurrentScheduleAction(subschedule) == 'set' && returnCurrentScheduleMode(subschedule) == '4'">{{ returnCurrentScheduleTemp(subschedule) }}°C</span>
        <span class="device-temp inactive" *ngIf="returnCurrentScheduleMode(subschedule) == '3' || returnCurrentScheduleMode(subschedule) == '2' || returnCurrentScheduleMode(subschedule) == '0'">--°C</span>
      </div>
    </div>
</div>
<div class="illustration-holder" *ngIf="deviceServ.returnSchedules().length == 0">
  <div class="illustration schedule"></div>
  <div class="main-text">{{ langServ.returnTranslate('label_no_schedules') }}</div>
  <div class="secondary-text">{{ langServ.returnTranslate('label_add_schedule_hint') }}</div>
  <div class="bottom-bar">
    <div class="add-btn" (click)="presentAddSchedule()" style="margin-bottom:40px;">{{ langServ.returnTranslate('btn_add') }}</div>
  </div>
</div>
</div>
