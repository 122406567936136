import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../devices.service';
import { LanguageService } from '../language-service.service';

@Component({
  selector: 'app-buy',
  templateUrl: './buy.component.html',
  styleUrls: ['./buy.component.scss']
})
export class BuyComponent implements OnInit {

  constructor(public deviceServ:DevicesService, public langServ:LanguageService) { }

  ngOnInit() {
  }

  openBuy() {
    window.open("https://icontrol.bg/bg/inteligentno-wi-fi-upravlenie-za-klimatici-icon-fgl.html", "_blank");
  }
}
