import { Component } from '@angular/core';
import { LanguageService } from './language-service.service';
import { DevicesService } from './devices.service';
import { CommunicationService } from './communication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  username:any = "";
  password:any = "";

  usernameRegister:any = "";
  passwordRegister:any = "";
  nameRegister:any = "";

  step:any = "login";

  modal:any = { open:false, cntx:"", obj:{} };

  error:any = "";
  errorRegister:any = "";

  verificaitonCode:any = "";
  cameFrom:any = "";

  errorVerify:any = "";
  forgotEmail:any = "";
  forgotError:any = "";

  showLoginPassword:any = "password";
  showRegisterPassword:any = "password";

  focused:any = false;

  constructor(public languageServ:LanguageService, public deviceServ:DevicesService, private commService:CommunicationService) { }

  ngOnInit() {
    this.deviceServ.setPhoneInformation("web", "browser", "11", "macbook", "0");
    this.deviceServ.setupCommunication(this.commService);
    // this.platform.backButton.subscribeWithPriority(10, () => {
    //   console.log('Handler was called!');
    // });
    // if(!this.languageServ.returnIsSetUp()) { this.openModal("select-language", { language:"en" }) }
  }

  Login() { 
    if(this.validateEmail(this.username)) {
      this.error = "";
      this.deviceServ.Login(this.username,this.password, this);
    } else {
      this.error = "email";
    }

  }
  Register() { 
    if(this.usernameRegister != '' && this.passwordRegister != '' && this.nameRegister != '') {
      if(this.validateEmail(this.usernameRegister)) {
        this.errorRegister = "";
        // this.deviceServ.Register(this.usernameRegister, this.passwordRegister, this.nameRegister, this); 
      } else {
        this.errorRegister = "email";
      }
    } else {
      this.errorRegister = 'empty';
    }
  }

  Verify() {
    if(this.cameFrom == 'login') {
      // this.deviceServ.Verify(this.username, this.password,this.verificaitonCode, this);
    } else {
      // this.deviceServ.Verify(this.usernameRegister, this.passwordRegister,this.verificaitonCode, this);
    }
  }

  switchStep(step) { this.step = step; }
  removeSymbol(){ 
    this.verificaitonCode = this.verificaitonCode.substring(0, this.verificaitonCode.length - 1); 
    if(this.verificaitonCode.length) { this.errorVerify = ''; }
  }
  typeInVerification(number) {  if(this.verificaitonCode.length < 4) { this.verificaitonCode += "" + number; } }

  openModal(cntx, obj){ this.modal = { open:true, 'cntx':cntx, 'obj':obj } }
  closeModal() { this.modal.open = false; }

  selectLanguage(){ 
    // this.languageServ.setLanguage(this.modal.obj.language); 
  }
  storeLanguage(){
    // this.languageServ.storeLanguage();
    this.closeModal();
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  close() {
    // this.modalCtrl.dismiss({
    //   'dismissed': true
    // });
  }

  ForgotPassword() {
    if(this.validateEmail(this.forgotEmail)) {
      this.forgotError = "";
      // this.deviceServ.ForgotPassword(this.forgotEmail, this);
    } else {
      this.forgotError = "email";
    }

  }

  goBackFromVerification() {
    if(this.cameFrom == 'login') {
      this.step = "login";
    } else {
      this.step = "register";
    }
  }

  switchLoginPassword() {
    if(this.showLoginPassword == 'text') { this.showLoginPassword = "password"; }
    else { this.showLoginPassword = "text"; }
  }

  switchRegisterPassword() {
    if(this.showRegisterPassword == 'text') { this.showRegisterPassword = "password"; }
    else { this.showRegisterPassword = "text"; }
  }


  returnVerification(idx) { if(this.verificaitonCode.length >= idx + 1) { return this.verificaitonCode[idx] } else { return ""; } }
  returnVerificationEmail() {
    if(this.cameFrom == 'login') {
      return this.username;
    } else {
      return this.usernameRegister;
    }
  }
}
