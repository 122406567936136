
<div class="web-header">
  <span class="title">Магазин</span>
  <!-- <div class="header-btn left"></div> -->
  <!-- <div class="header-btn right add-ic"></div> -->
</div>
<div class="content-holder">
  <div class="stretch-card">
    <div class="avatar-logo buy" style="margin-top:20px;"></div>
    <span class="description">{{ langServ.returnTranslate('label_store_description') }}</span>
    <div class="add-btn" style="margin-bottom:40px;" (click)="openBuy()">{{ langServ.returnTranslate('btn_buy') }}</div>
  </div>
</div>
