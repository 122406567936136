<div class="modal-layer" [ngClass]="{ 'active':modal.open }">
  <div class="backdrop" (click)="closeModal()"></div>
  <div class="modal md" *ngIf="modal.cntx == 'device-info'">
    <div class="step" *ngIf="modal.obj.current_view == 'general'">
      <div class="modal-title">Информация <div (click)="closeModal()" class="exit-btn"></div></div>
      <div class="selection-row">
        <span>Сериен номер</span>
        <span class='note'>{{ modal.obj.serial }}</span>
      </div>
      <div class="selection-row">
        <span>Наименование</span>
        <span class='note'>{{ modal.obj.name }}</span>
      </div>
      <div class="selection-row">
        <span>МAC</span>
        <span class='note'>{{ modal.obj.mac }}</span>
      </div>
      <div class="selection-row">
        <span>Firmware</span>
        <div class="small-action" *ngIf="modal.obj.updatable && returnIsDeviceActive(modal.obj) && modal.obj.updating == '0' || modal.obj.firmware != modal.obj.latest_firmware && returnIsDeviceActive(modal.obj) && modal.obj.updating == '0' && modal.obj.firmware != '1.0.8'" (click)="openModal('update-firmware', {})">
          <i class="icon upload"></i>
        </div>
        <span class='note'>{{ modal.obj.firmware }} <span style="float:right;font-size:8px;color:black;" *ngIf="modal.obj.updating == '1'">(Актулизация в процес)</span></span>
      </div>
      <div class="selection-row selectable" [ngClass]="{ 'disabled':!returnIsDeviceActive(modal.obj) || modal.obj.firmware == '1.0.0' }">
        <span>Мрежа</span>
        <i class="icon large-back-icon arrow-forward" style="height:12px;width:12px;margin-top:24px;opacity:0.7;margin-left:5px;"></i>
        <span class='note'>{{ modal.obj.wifi_ssid }}</span>
      </div>
      <div class="selection-row selectable" (click)="modal.obj.current_view = 'ir_protocol'" *ngIf="IsDeviceUNI(modal.obj)" [ngClass]="{ 'disabled':!returnIsDeviceActive(modal.obj) }">
        <span>IR Протокол</span>
        <i class="icon large-back-icon arrow-forward" style="height:12px;width:12px;margin-top:24px;opacity:0.7;margin-left:5px;"></i>
        <span class="note" *ngIf="modal.obj.ir_protocol != '-2' && modal.obj.ir_protocol != '-1'">{{ deviceServ.returnProtocolName(modal.obj.ir_protocol) }}</span>
        <span class="note" *ngIf="modal.obj.ir_protocol == '-2' || modal.obj.ir_protocol == '-1'">Изберете протокол</span>
      </div>
  
      <div class="selection-row" (click)="switchInputView()" *ngIf="IsDeviceUNI(modal.obj) && firmware_number > 200" [ngClass]="{ 'disabled':!returnIsDeviceActive(modal.obj) }">
        <span>Настройка на цифрови входове</span>
        <i class="icon large-back-icon arrow-forward" style="height:12px;width:12px;margin-top:24px;opacity:0.7;margin-left:5px;"></i>
      </div>
    </div>
    <div class="step" style="float:left;width:100%;height:100%" *ngIf="modal.obj.current_view == 'ir_protocol'">
      <div class="modal-title"><div (click)="modal.obj.current_view = 'general'" class="back-btn"></div>IR Протокол <div (click)="closeModal()" class="exit-btn"></div></div>
      <span class="step-hint">Изберете подходящ протокол за вашия климатик</span>
      <div class="select-list">
        <div class="selection-row selectable" (click)="selectProtocol('-2')">
          <span>Разпознай автоматично</span>
        </div>
        <div class="selection-row selectable" *ngFor="let protocol of deviceServ.returnProtocols()" (click)="selectProtocol(protocol.num)">
          <span>{{ protocol.name }}</span>
          <i class="icon selected-tick large-back-icon" *ngIf="protocol.num == modal.obj.ir_protocol" style="background-color:#FF725B;border-radius:5px;background-size:14px;"></i>
        </div>
      </div>
    </div>
    <div class="illustration-holder step" *ngIf="modal.obj.current_view == 'detect_ir'">
      <div class="illustration remote no-title"></div>
      <div class="main-text">Включете климатика</div>
      <div class="secondary-text">Моля използвайте бутона на дистанционното за да включите климатика</div>
    </div>
  
    <div class="illustration-holder step" *ngIf="modal.obj.current_view == 'detected_ir'">
      <div class="illustration remote-success no-title"></div>
      <div class="main-text">Успешна настройка</div>
      <div class="secondary-text">Протокола на климатиак е разпознат успешно</div>
      <div class="bottom-bar">
        <div class="add-btn" (click)="modal.obj.current_view = 'general'" style="margin-bottom:40px;">Напред</div>
      </div>      
    </div>

    <div class="step" *ngIf="current_view == 'scanning_wifi'">
      <span class="step-hint">Изберете мрежа</span>
      <div class="selection-row" *ngFor="let network of returnDevice(modal.obj.serial).networks" (click)="setNetwork(network.name, network.encryption)">
        <span>{{ network.name }}</span>
        <i class="icon selected-tick large-back-icon" *ngIf="network.name == device.wifi_ssid" style="background-color:#FF725B;border-radius:5px;background-size:14px;"></i>
      </div>
    </div>
  </div>
  <div class="modal prompt" *ngIf="modal.cntx == 'rename-device'">
    <div class="modal-title">Преименуване <div (click)="closeModal()" class="exit-btn"></div></div>
    <div class="input-row">
      <input type="text" placeholder="Наименование" [(ngModel)]="modal.obj.name" />
      <label>Наименование</label>
    </div>
    <div class="btn-holder">
      <div class="btn back">Отказ</div>
      <div class="btn positive">Промяна</div>
    </div>
  </div>
</div>
<!-- <ion-header>
    <ion-toolbar>
      <ion-title>
        {{ langServ.returnTranslate('title_air_conditioners') }}
      </ion-title>
      <div class="icon-right" (click)="presentAddDeviceModal()">
        <i class="icon add"></i>
      </div>
    </ion-toolbar>
  </ion-header> -->
  <div class="web-header">
    <span class="title">Климатици</span>
    <!-- <div class="header-btn left"></div> -->
    <!-- <div class="header-btn right add-ic"></div> -->
  </div>

    <div class="content-holder">
      <div class="ac-new-row" *ngFor="let device of deviceServ.returnDevices()">
        <div class='ac-new-row-header'>
          <div class="icon shared" (click)="presentModal(device)" *ngIf="device.shared" class="icon shared"></div>
          <div class="name" [ngClass]="{ 'not-shared':!device.shared }" (click)="presentModal(device)">{{ device.name }}</div>
          <div class="more" (click)="openDropdown('more-options', device)"></div>
          <div class='dropdown-list' clickOutside (onClickOutside)="closeDropdown()" [ngClass]="{ 'active':dropdown.open && dropdown.cntx == 'more-options' && dropdown.obj.serial == device.serial }">
            <span class="dropdown-title">{{ device.name }}</span>
            <div class="dropdown-item" (click)="openModal('device-info', device)"><i class="icon info"></i> <span>Информация за устройства</span></div>
            <div class="dropdown-item" (click)="openModal('rename-device', device)"><i class="icon edit"></i> <span>Преименуване</span></div>
            <div class="dropdown-item"><i class="icon share"></i> <span>Споделяне</span></div>
            <div class="dropdown-item negative"><i class="icon remove"></i> <span>Изтриване</span></div>
          </div>
        </div>
        <div class="status-row">
          <div class="temp" (click)="presentModal(device)" *ngIf="returnCurrentPower(device) == 'ON' && returnCurrentMode(device) != 'FAN' && device.active && device.communicated && device.updating == '0'">{{ returnCurrentTemp(device) }}°C</div>
          <div class="temp inactive" (click)="presentModal(device)" *ngIf="returnCurrentPower(device) == 'OFF' && device.active && device.communicated && device.updating == '0' || returnCurrentMode(device) == 'FAN' && device.active && device.communicated && device.updating == '0'">--°C</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="!device.active && device.initialized && device.updating == '0'">{{ langServ.returnTranslate('label_no_connection') }}</div>
          <div class="temp connection updating" (click)="presentModal(device)" *ngIf="device.updating == '1'">Актуализация на устройство...</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="device.active && !device.communicated && device.initialized && !IsDeviceUNI(device) && device.updating == '0'">{{ langServ.returnTranslate('label_cabel_problem') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="device.active && !device.communicated && device.initialized && IsDeviceUNI(device) && device.updating == '0'">{{ langServ.returnTranslate('label_no_protocol') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="!device.initialized && device.updating == '0'">{{ langServ.returnTranslate('label_connecting') }}</div>
          <div class="house-temp" *ngIf="device.active && IsDeviceUNI(device) && device.communicated && device.updating == '0'">
            <i class="icon house"></i>{{device.room_temperature}}°C
            <div class="tooltip">
              <span class="tooltiptext">В момента</span>
            </div> 
          </div>
          <div class="status" *ngIf="returnCurrentPower(device) == 'ON' && device.active && device.communicated && device.updating == '0'" (click)="presentModal(device)">
            <i class="icon cold" *ngIf="returnCurrentMode(device) == 'COOL'">
              <div class="tooltip">
                <span class="tooltiptext">Студено</span>
              </div> 
            </i>
            <i class="icon hot" *ngIf="returnCurrentMode(device) == 'HEAT'">
              <div class="tooltip">
                <span class="tooltiptext">Топло</span>
              </div> 
            </i>
            <i class="icon fan" *ngIf="returnCurrentMode(device) == 'FAN'">
              <div class="tooltip">
                <span class="tooltiptext">Вентилатор</span>
              </div> 
            </i>
            <i class="icon dry" *ngIf="returnCurrentMode(device) == 'DRY'">
              <div class="tooltip">
                <span class="tooltiptext">Сух</span>
              </div> 
            </i>
            <i class="icon auto" *ngIf="returnCurrentMode(device) == 'AUTO'">
              <div class="tooltip">
                <span class="tooltiptext">Автоматичен</span>
              </div> 
            </i>
          </div>
          <div class="status active" *ngIf="returnCurrentPower(device) == 'ON' && device.active && device.communicated && device.updating == '0'" (click)="presentModal(device)">
            <i *ngIf="returnCurrentFanSpeed(device) == 1" class="icon fan-quiet">
              <div class="tooltip">
                <span class="tooltiptext">Тих</span>
              </div> 
            </i>
            <i *ngIf="returnCurrentFanSpeed(device) == 5" class="icon fan-auto">
              <div class="tooltip">
                <span class="tooltiptext">Автоматичен</span>
              </div> 
            </i>
            <i *ngIf="returnCurrentFanSpeed(device) == 2" class="icon fan-low">
              <div class="tooltip">
                <span class="tooltiptext">Слаб</span>
              </div> 
            </i>
            <i *ngIf="returnCurrentFanSpeed(device) == 3" class="icon fan-medium">
              <div class="tooltip">
                <span class="tooltiptext">Среден</span>
              </div> 
            </i>
            <i *ngIf="returnCurrentFanSpeed(device) == 4" class="icon fan-high">
              <div class="tooltip">
                <span class="tooltiptext">Силен</span>
              </div> 
            </i>
          </div>
        </div>
        <div class="controls-row">
          <div class="device-power-btn"  [ngClass]="{ 'on':returnCurrentPower(device) == 'ON', 'off':returnCurrentPower(device) == 'OFF', 'inactive':!device.active || !device.communicated || device.updating == '1' }" (click)="switchPower(device)"><div class="inner"><i class="icon power"></i></div></div>
          <div class="control-btn" (click)="setTemperature(device, returnCurrentTemp(device) + 1)"><div class="inner"><i [ngClass]="{ 'disabled':returnCurrentPower(device) == 'OFF' || returnCurrentMode(device) == 'FAN' || !device.active || !device.communicated  }" class="icon temp-up"></i></div></div>
          <div class="control-btn"(click)="setTemperature(device, returnCurrentTemp(device) - 1)"><div class="inner"><i [ngClass]="{ 'disabled':returnCurrentPower(device) == 'OFF' || returnCurrentMode(device) == 'FAN' || !device.active || !device.communicated }" class="icon temp-down"></i></div></div>
        </div>
        <div class="gray-bg"></div>
      </div>
      <!-- <div class="ac-row" *ngFor="let device of deviceServ.returnDevices()" [ngClass]="{ 'on':returnCurrentPower(device) == 'ON' }">
        <span class="name" (click)="presentModal(device)"><span style="float:left;">{{ device.name }}</span> <i *ngIf="device.shared" class="icon shared"></i></span>
        <div class="more" (click)="deviceServ.openModal('device-details', device)"></div>
        <div style="float: left;height: 56px;width: calc(100% - 90px);" (click)="presentModal(device)">
          <div class="temp" (click)="presentModal(device)" *ngIf="returnCurrentPower(device) == 'ON' && returnCurrentMode(device) != 'FAN' && device.active && device.communicated">{{ returnCurrentTemp(device) }}°C</div>
          <div class="temp inactive" (click)="presentModal(device)" *ngIf="returnCurrentPower(device) == 'OFF' && device.active && device.communicated || returnCurrentMode(device) == 'FAN' && device.active && device.communicated">--°C</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="!device.active && device.initialized">{{ langServ.returnTranslate('label_no_connection') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="device.active && !device.communicated && device.initialized && !IsDeviceUNI(device)">{{ langServ.returnTranslate('label_cabel_problem') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="device.active && !device.communicated && device.initialized && IsDeviceUNI(device)">{{ langServ.returnTranslate('label_no_protocol') }}</div>
          <div class="temp connection" (click)="presentModal(device)" *ngIf="!device.initialized">{{ langServ.returnTranslate('label_connecting') }}</div>
          <div class="status-icon" *ngIf="returnCurrentPower(device) == 'ON'" (click)="presentModal(device)">
            <div class="icon cold" *ngIf="returnCurrentMode(device) == 'COOL'"></div>
            <div class="icon hot" *ngIf="returnCurrentMode(device) == 'HEAT'"></div>
            <div class="icon fan" *ngIf="returnCurrentMode(device) == 'FAN'"></div>
            <div class="icon dry" *ngIf="returnCurrentMode(device) == 'DRY'"></div>
            <div class="icon auto" *ngIf="returnCurrentMode(device) == 'AUTO'"></div>
          </div>
          <div class="icon ho [ngClass]="{ 'on':returnCurrentPower(device) == 'ON', 'off':returnCurrentPower(device) == 'OFF', disabled:!device.active || !device.communicated }" (click)="switchPower(device)"use" *ngIf="device.active && IsDeviceUNI(device) && device.communicated">{{device.room_temperature}}°C</div>
        </div>
        <div class="device-power-btn">
          <div class="inner"><i class="icon power"></i></div>
        </div> -->
        <!-- <div class="switch" [ngClass]="{ 'active':returnCurrentPower(device) == 'ON', 'disabled':!device.active }" (click)="switchPower(device)"></div> -->
      <!-- </div> -->
      <div class="illustration-holder" *ngIf="deviceServ.returnDevices().length == 0">
        <div class="illustration ac"></div>
        <div class="main-text">{{ langServ.returnTranslate('label_no_devices') }}</div>
        <div class="secondary-text">{{ langServ.returnTranslate('label_add_device_hint') }}</div>
        <div class="bottom-bar">
          <div class="add-btn" (click)="presentAddDeviceModal()" style="margin-bottom:40px;">{{ langServ.returnTranslate('btn_add') }}</div>
        </div>
      </div>
    </div>
   