import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../devices.service';
import { LanguageService } from '../language-service.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  language:any = "";
  gridNumber:any;

  constructor( public deviceServ:DevicesService, public langServ:LanguageService) {
    this.language = this.langServ.returnLanguage();
    this.gridNumber = this.deviceServ.returnGridNumber().toString();
  }

  ngOnInit() {
  }

  selectLanguage(){ 
    this.langServ.setLanguage(this.language);
    this.langServ.storeLanguage(); 
  }

  switchGridNumber(){
    this.deviceServ.switchGridNumber(parseInt(this.gridNumber));
  }

}
