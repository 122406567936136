import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommunicationService } from '../communication.service';
import { DevicesService } from '../devices.service';
import { LanguageService } from '../language-service.service';

@Component({
  selector: 'app-rooms',
  templateUrl: './rooms.component.html',
  styleUrls: ['./rooms.component.scss']
})
export class RoomsComponent implements OnInit {
  ttl:any = "Стаи";
  page:any = "";

  currentRoom:any = { uid:0 };

  constructor(public deviceServ:DevicesService, private router: Router, private zone:NgZone, private commServ:CommunicationService, public langServ:LanguageService) {
  }

  add() { }
  back() { 
    this.page = ""; 
    this.ttl = "Стаи";
    this.currentRoom = { uid:0 };
    this.zone.run(() => {});
  }
  openRoom(room) {
    this.currentRoom = room;
    this.ttl = room.name;
    this.page = 'view-room';
    this.zone.run(() => {});
  }

  ngOnInit() {
  }

  switchPower(device) {
    if(this.deviceServ.returnDevice(device.serial).power == 'ON') {
      this.commServ.sendMessage("test", device.serial + ";power_off");
    } else {
      this.commServ.sendMessage("test", device.serial + ";power_on");
    }
  }


  switchRoomPower(room) {
    let room_devices = this.deviceServ.returnRoomDevices(room);
    if(this.deviceServ.returnRoomActiveDevices(room).length == room_devices.length) {
      for(let i = 0; i < room_devices.length; i++) {
        this.commServ.sendMessage("device/" + room_devices[i].serial, room_devices[i].serial + ";power_off");
        room_devices[i].power_set = "OFF";
      }
    } else {
      for(let i = 0; i < room_devices.length; i++) {
        this.commServ.sendMessage("device/" + room_devices[i].serial, room_devices[i].serial + ";power_on");
        room_devices[i].power_set = "ON";
      }
    }
  }

  returnCurrentPower(device) {
    if(this.deviceServ.returnDevice(device.serial).power_set != "") { return this.deviceServ.returnDevice(device.serial).power_set; }
    else { return this.deviceServ.returnDevice(device.serial).power; }
  }

  returnCurrentTemp(device) {
    if(this.deviceServ.returnDevice(device.serial).temp_set != 0) { return this.deviceServ.returnDevice(device.serial).temp_set; }
    else { return this.deviceServ.returnDevice(device.serial).temperature; }
  }

  returnCurrentMode(device) {
    if(this.deviceServ.returnDevice(device.serial).mode_set != "") { return this.deviceServ.returnDevice(device.serial).mode_set; }
    else { return this.deviceServ.returnDevice(device.serial).mode; }
  }

}
