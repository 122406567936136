<!-- <div class="selection-row" (click)="openLanguage()">
    <i class="large-front-icon language icon"></i>
    <span>{{ langServ.returnTranslate('settings_label_language') }}</span>
    <ion-select class="noclick" [(ngModel)]="language" #languageSelect>
      <ion-select-option value="en">English</ion-select-option>
      <ion-select-option value="bg">Български</ion-select-option>
      <ion-select-option value="gr">Ελληνικά</ion-select-option>
      <ion-select-option value="ro">Română</ion-select-option>
    </ion-select>
  </div> -->
  <div class="web-header">
    <span class="title">Настройки</span>
    <!-- <div class="header-btn left"></div> -->
    <!-- <div class="header-btn right add-ic"></div> -->
  </div>
  <div class="content-holder">
    <div class="selection-row"><i class="large-front-icon profile icon"></i> <span>{{ langServ.returnTranslate('settings_label_profile') }}</span></div>
    <div class="selection-row"><i class="large-front-icon help icon"></i> <span>{{ langServ.returnTranslate('settings_label_help') }}</span></div>
    <div class="selection-row"><i class="large-front-icon contacts icon"></i> <span>{{ langServ.returnTranslate('settings_label_contacts') }}</span></div>
    <div class="selection-row"><i class="large-front-icon version icon"></i> <span>{{ langServ.returnTranslate('settings_label_version') }}</span></div>
  </div>
  
  <!-- <div class="selection-row" *ngIf="deviceServ.returnPhoneWidth() > 699"><i class="large-front-icon version icon"></i> <span>Режим "Решетка"</span> <div class="switch" (click)="deviceServ.switchGridActive()" [ngClass]="{ 'active':deviceServ.returnGrid() }"></div></div> -->