<div class="web-header">
  <span class="title">Стаи</span>
  <!-- <div class="header-btn left"></div> -->
  <!-- <div class="header-btn right add-ic"></div> -->
</div>
<div class="content-holder">
  <div class="room-row" [ngClass]="{ 'expanded':room.expanded }" *ngFor="let room of deviceServ.returnRooms()" (click)="openRoom(room)">
    <div class="icon-holder">
      <i class="icon {{room.type}}"></i>
    </div>
    <div class="info-holder" (click)="viewRoomModal(room.uid)">
      <span class="name">{{ room.name }}</span>
      <span class="mode">{{ deviceServ.returnRoomActiveDevices(room).length }} / {{ deviceServ.returnRoomDevices(room).length }} {{ langServ.returnTranslate('label_devices') }}</span>
    </div>
    <div class="more" (click)="deviceServ.openModal('room-details', room)"></div>
    <div class="details-btn" [ngClass]="{ 'expanded':room.expanded }" (click)="room.expanded = !room.expanded">{{ langServ.returnTranslate('label_quick_details') }} <i class='icon arrow-down'></i></div>
    <div class="device-power-btn" (click)="switchRoomPower(room)" [ngClass]="{ 'on':deviceServ.returnRoomActiveDevices(room).length == deviceServ.returnRoomDevices(room).length, 'off':deviceServ.returnRoomActiveDevices(room).length != deviceServ.returnRoomDevices(room).length }">
      <div class="inner"><i class="icon power"></i></div>
    </div>
    <!-- <div class="switch" (click)="switchRoomPower(room)" [ngClass]="{ 'active':deviceServ.returnRoomActiveDevices(room).length == deviceServ.returnRoomDevices(room).length }"></div> -->
    <div class="device-list">
      <div class="device-row" *ngFor="let device of deviceServ.returnRoomDevices(room)">
        <span class="device-name">{{ device.name }}</span>
        <div class="device-mode" *ngIf="returnCurrentPower(device) == 'ON'">
          <div class="icon cold" *ngIf="returnCurrentMode(device) == 'COOL'"></div>
          <div class="icon hot" *ngIf="returnCurrentMode(device) == 'HEAT'"></div>
          <div class="icon fan" *ngIf="returnCurrentMode(device) == 'FAN'"></div>
          <div class="icon dry" *ngIf="returnCurrentMode(device) == 'DRY'"></div>
          <div class="icon auto" *ngIf="returnCurrentMode(device) == 'AUTO'"></div>
        </div>
        <span class="device-temp" *ngIf="returnCurrentMode(device) == 'HEAT' && returnCurrentPower(device) == 'ON' || returnCurrentMode(device) == 'COOL' && returnCurrentPower(device) == 'ON'">{{ device.temperature }}°C</span>
        <span class="device-temp inactive" *ngIf="returnCurrentMode(device) == 'FAN' || returnCurrentMode(device) == 'AUTO' || returnCurrentMode(device) == 'DRY' || returnCurrentPower(device) == 'OFF'">--°C</span>
      </div>
    </div>
</div>
<div class="illustration-holder" *ngIf="deviceServ.returnRooms().length == 0">
  <div class="illustration room"></div>
  <div class="main-text">{{ langServ.returnTranslate('label_no_rooms') }}</div>
  <div class="secondary-text">{{ langServ.returnTranslate('label_add_room_hint') }}</div>
  <div class="bottom-bar">
    <div class="add-btn" (click)="presentAddRoomModal()" style="margin-bottom: 40px;">{{ langServ.returnTranslate('btn_add') }}</div>
  </div>
</div>
</div>
