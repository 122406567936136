<div class="login-screen" *ngIf="!deviceServ.returnLogged()">
  <div class="window">
    <div class="left-side">
      <i class="logo"></i>
      <div class="login-form">
        <span class="ttl">Вход</span>
        <span class="ttl-info">Моля въведете вашите данни за вход</span>

        <div class="input-row primary">
          <span class="label">Е-майл</span>
          <input type="text" [(ngModel)]="username" (keyup.enter)="Login()" />
        </div>
        <div class="input-row">
          <span class="label">Парола</span>
          <input type="password" [(ngModel)]="password" (keyup.enter)="Login()" />
        </div>

        <div class="login-line">
          <div class="remember"><input type='checkbox'><span>Запомни ме</span></div>
          <div class="forgotten-password">Забравена парола?</div>
        </div>

        <div class="login-btn" (click)="Login()">ВХОД</div>
        <span class="btn-addon">Не сте регистрирани? <span class="hyperlink">Създайте акаунт</span></span>
      </div>
    </div>
    <div class="right-side">
      
    </div>
  </div>
</div>

<div style="float:left;width:100%;height:100%" *ngIf="deviceServ.returnLogged()">
  <router-outlet></router-outlet>
  <div class="tab-bar">
    <div class="icon-holder">
      <div class="tab-ic home-tab" routerLink="/home" routerLinkActive="active">
        <div class="tooltip">
          <span class="tooltiptext">Климатици</span>
        </div>
      </div>
      <div class="tab-ic room-tab"  routerLink="/rooms" routerLinkActive="active">
        <div class="tooltip">
          <span class="tooltiptext">Стаи</span>
        </div>
      </div>
      <div class="tab-ic schedule-tab" routerLink="/schedules" routerLinkActive="active">
        <div class="tooltip">
          <span class="tooltiptext">Сценарии</span>
        </div>
      </div>
      <div class="tab-ic buy-tab" routerLink="/buy" routerLinkActive="active">
        <div class="tooltip">
          <span class="tooltiptext">Магазин</span>
        </div>
      </div>
      <div class="tab-ic settings-tab" routerLink="/settings" routerLinkActive="active">
        <div class="tooltip">
          <span class="tooltiptext">Настройки</span>
        </div>
      </div>
    </div>
  </div>
</div>