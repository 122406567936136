import { Injectable, NgZone } from '@angular/core';
import { LanguageService } from './language-service.service';
import { HttpClient } from '@angular/common/http';

declare var sha1;
@Injectable({
  providedIn: 'root'
})
export class DevicesService {
// Operational variables
software:any = "1.0.0";
last_update_software = "06.09.2021";
phone:any = { uuid:"", platform:"", platform_version:"", model:"", user:"", name:"", email:"" }
protocol_classifier = [{ num:"16", name:"DAIKIN" }, { num:"33", name:"FUJI" }, { num:"33;ARRAH2E", name:"FUJI 1 (ARRAH2E)" }, { num:"33;ARDB1", name:"FUJI 2 (ARDB1)" }, { num:"33;ARREB1E", name:"FUJI 3 (ARREB1E)" }, { num:"33;ARJW2", name:"FUJI 4 (ARJW2)" }, { num:"33;ARRY4", name:"FUJI 5 (ARRY4)" }, { num:"38", name:"HAIER" }, { num:"40", name:"HITACHI" }, { num:"34", name:"MIDEA" }, { num:"49", name:"PANASONIC" }, { num:"46", name:"SAMSUNG" }, {num:"57", name:"SANG/TCL/Chigo" }, { num:"57;SANG", name:"SANG" }, { num:"57;TCL", name:"TCL" }, { num:"57;Chigo", name:"Chigo" }, { num:"10", name:"LG" }, { num:"10;GE6711AR2853M", name:"LG (GE6711AR2853M)" },  { num:"10;AKB75215403", name:"LG (AKB75215403)" }, { num:"20", name:"MITSUBISHI" }, { num:"24", name:"GREE" }, { num:"14", name:"SHARP" }]

// <ion-select-option value="37">CARRIER</ion-select-option>
// <ion-select-option value="16">DAIKIN</ion-select-option>
// <ion-select-option value="48">ELECTRA</ion-select-option>
// <ion-select-option value="33">FUJI ELECTRIC</ion-select-option>
// <ion-select-option value="38">HAIER</ion-select-option>
// <ion-select-option value="40">HITACHI</ion-select-option>
// <ion-select-option value="34">MIDEA</ion-select-option>
// <ion-select-option value="49">PANASONIC</ion-select-option>
// <ion-select-option value="46">SAMSUNG</ion-select-option>
// <ion-select-option value="57">SANG</ion-select-option>
// <ion-select-option value="84">SANYO</ion-select-option>
// <ion-select-option value="62">SHARP</ion-select-option>
// <ion-select-option value="32">TOSHIBA</ion-select-option>
// <ion-select-option value="54">VESTEL</ion-select-option>
// <ion-select-option value="45">WHIRLPOOL</ion-select-option>
devices:any = [ ];
unassigned_devices:any = [];
schedules:any = [];
factoredSchedules:any = [];
rooms:any = [];
loader:any;

width:any;

activeTimeInterval:any;
unassignedTimeInterval:any;

// Seperate components
communication:any;
page:any = "";
modal:any = { open:false, cntx:"", obj:{} };
addModal:any = null;

lastObject:any = {};

// Voice commands variables
voiceActive:any = false;
speechOptions = { language: "bg-BG", showPopup: false, showPartial: true }

gridActive:any = false;
gridNumber:any = 3;

logged:any = false;

constructor(public languageServ:LanguageService, private http:HttpClient, private zone:NgZone) {
  clearInterval(this.activeTimeInterval);
  clearInterval(this.unassignedTimeInterval);
  this.activeTimeInterval = setInterval(() => {
    this.checkDevicesActive(); 
  }, 5000);
  this.unassignedTimeInterval = setInterval(() => {
    
  }, 2000);
}

handleLanguageSetup(data, uuid, platform, platform_version, model, user) {
  if(data == "error"){
    this.languageServ.setLanguage("en");
    this.languageServ.setup(false);
  } else {
    this.languageServ.setLanguage(data);
    this.languageServ.setup(true);
  }
  this.phone = { "uuid":uuid, "platform":platform, "platform_version":platform_version, "model":model, "user":user };
  this.getPhoneInformation();
}

setPhoneInformation(uuid, platform, platform_version, model, user) { 
  this.handleLanguageSetup("bg", "123456789", "browser", "11", "macbook", "0")
  // this.nativeStorage.getItem('language')
  // .then(
  //   data => this.handleLanguageSetup(data, uuid, platform, platform_version, model, user),
  //   error => this.handleLanguageSetup("error", uuid, platform, platform_version, model, user)
  // );
  // this.nativeStorage.getItem('grid')
  // .then(
  //   data => this.gridActive = data,
  //   error => this.nativeStorage.setItem('grid', false)
  // );
  // this.nativeStorage.getItem('grid_num')
  // .then(
  //   data => this.gridNumber = data,
  //   error => this.nativeStorage.setItem('grid_num', 1)
  // );
  // this.appVersion.getVersionNumber().then((data) => this.software = data);
}
getPhoneInformation(){
  // this.wait(this.languageServ.returnTranslate('loading_data'));
  this.http.get("api/icon_service.php?action=get_devices&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model)
  .subscribe(response => {
    this.logged = response['logged'];
    if(response['logged']) {
      this.devices = response['devices'];
      for(let i = 0; i < this.devices.length; i++){
        this.devices[i].power = "OFF";
        this.devices[i].mode = "FAN";
        this.devices[i].temperature = 6553;
        this.devices[i].fan_speed = 5;
        this.devices[i].power_set = "";
        this.devices[i].mode_set = "";
        this.devices[i].fan_speed_set = "";
        this.devices[i].temp_set = 0;
        this.devices[i].active = false;
        this.devices[i].communicated = true;
        this.devices[i].initialized = false;
        this.devices[i].last_active = new Date();
        this.devices[i].last_communicated = new Date();
        this.devices[i].room_temperature = 0;
        this.devices[i].fan_position = "1";
        this.devices[i].fan_position_type = "MANUAL";
        if(this.devices[i].serial.indexOf("UNI") > -1) { this.devices[i].ir_protocol = -2; }
        if(this.devices[i].shared){
          this.communication.AddAdditionalUser(this.devices[i].user);
        }
      }
      this.phone.user = response['user'];
      this.phone.email = response['email'];
      this.phone.name = response['name'];
      this.communication.setUUID(this.phone.uuid);
      this.communication.setUser(this.phone.user);
      this.communication.connectUs(this.phone.user);
      this.getRooms();
      this.getSchedules();
    }
  });
}
setDeviceInformation(serial, power, mode, temperature, fan, error_code, room_temperature, firmware, fan_position, fan_position_type){
  for(let i = 0; i < this.devices.length;i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].power = power;
      this.devices[i].mode = mode;
      if(temperature != 6553) { this.devices[i].temperature = parseInt(temperature) };
      if(fan == "QUIET") { this.devices[i].fan_speed = 1; }
      else if(fan == "LOW") { this.devices[i].fan_speed = 2; }
      else if(fan == "MED") { this.devices[i].fan_speed = 3; }
      else if(fan == "HIGH") { this.devices[i].fan_speed = 4; }
      else if(fan == "AUTO") { this.devices[i].fan_speed = 5; }
      if(mode == this.devices[i].mode_set) { this.devices[i].mode_set = ""; }
      if(power == this.devices[i].power_set) { this.devices[i].power_set = ""; }
      if(parseInt(temperature) == this.devices[i].temp_set) { this.devices[i].temp_set = 0; }
      if(this.devices[i].fan_speed == parseInt(this.devices[i].fan_speed_set)) { this.devices[i].fan_speed_set = ""; }
      this.devices[i].active = true;
      this.devices[i].communicated = true;
      this.devices[i].initialized = true;
      let error_code_split = error_code.split(",");
      let error_string = error_code_split[0].toString() + "" + error_code_split[1].toString(16)[0];
      this.devices[i].error = error_string;
      this.devices[i].last_active = new Date();
      this.devices[i].last_communicated = new Date();
      this.devices[i].room_temperature = room_temperature;
      this.devices[i].firmware = firmware;
      this.devices[i].fan_position = fan_position;
      this.devices[i].fan_position_type = fan_position_type;
      this.zone.run(() => {  });
    }
  }
}

setIRPRotocol(serial, ir_protocol) {
  for(let i = 0; i < this.devices.length;i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].ir_protocol = ir_protocol;
      this.zone.run(() => {  });
    }
  }
}
setNewIRProtocol(serial, ir_protocol) {
  this.communication.sendMessage("device/" + serial, serial + ";ac_setup;" + ir_protocol); 
  for(let i = 0; i < this.devices.length;i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].ir_protocol = ir_protocol;
      this.zone.run(() => {  });
    }
  }
}

LoadNetworksForDevice(serial, networks) {
  for(let i = 0; i < this.devices.length;i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].networks = networks;
    }
  }
}

updateDevice(serial, firmware){
  for(let i = 0; i < this.devices.length;i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].active = true;
      this.devices[i].initialized = true;
      this.devices[i].last_active = new Date();
      this.devices[i].updating = '0';
      if(firmware != '') { this.devices[i].firmware = firmware; }
      this.zone.run(() => {  });
    }
  }
}

lateAdd(serial) {
  if(this.unassigned_devices.filter(device => device.serial == serial).length == 0){
    this.unassigned_devices.push({ serial:serial });
  }
}

updateFirmwareDevice(serial) {
  this.http.get("api/icon_service.php?action=update_firmware_device&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&serial=" + serial)
  .subscribe(response => {
    if(response['done']) {
      this.communication.sendMessage("device/" + serial, serial + ";firmware_upgrade"); 
      for(let i = 0; i < this.devices.length;i++){
        if(this.devices[i].serial == serial) {
          this.devices[i].updating = "1";
          this.zone.run(() => {  });
        }
      }
    }
  });
}

getDevices(){
  this.http.get("api/icon_service.php?action=get_devices&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model)
  .subscribe(response => {
    if(response['logged']) {
     this.devices = response['devices'];
     for(let i = 0; i < this.devices.length; i++){
       this.devices[i].power = "OFF";
       this.devices[i].mode = "FAN";
       this.devices[i].temperature = 6553;
       this.devices[i].fan_speed = 5;
       this.devices[i].power_set = "";
       this.devices[i].mode_set = "";
       this.devices[i].temp_set = 0;
       this.devices[i].fan_speed_set = "";
       this.devices[i].active = false;
       this.devices[i].communicated = true;
       this.devices[i].initialized = false;
       this.devices[i].last_active = new Date();
       this.devices[i].last_communicated = new Date();
       this.devices[i].room_temperature = 0;
       this.devices[i].fan_position = 1;
       this.devices[i].fan_position_type = "MANUAL";
       if(this.devices[i].serial.indexOf("UNI") > -1) { this.devices[i].ir_protocol = -2; }
       if(this.devices[i].shared){
         this.communication.AddAdditionalUser(this.devices[i].user);
       }
     }
     this.phone.email = response['email'];
     this.phone.name = response['name'];
     this.zone.run(() => { });
    } else {
      // this.openLogin();
    }
  });
}

getRooms(){
  this.http.get("api/icon_service.php?action=get_rooms&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model)
  .subscribe(response => {
    if(response['logged']) {
     this.rooms = response['rooms'];
     this.zone.run(() => { this.voiceActive = false; });
    } else {
      // this.openLogin();
    }
  });
}

getSchedules(){
  this.http.get("api/icon_service.php?action=get_test_schedules&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model)
  .subscribe(response => {
    if(response['logged']) {
     this.schedules = response['schedules'];
     this.factoredSchedules = [];
     for(let i = 0; i < this.schedules.length ;i++){
       if(this.factoredSchedules.filter(schedule => schedule.unique == this.schedules[i].unique).length > 0){
        this.factoredSchedules.filter(schedule => schedule.unique == this.schedules[i].unique)[0].total += 1;
       } else {
        this.factoredSchedules.push({ type:this.schedules[i].selectType, unique:this.schedules[i].unique, total:1, expanded:false });
       }
     }
     this.zone.run(() => {});
    }
  });
}

handleVoice(answers) {
  for (let answer of answers) {
    let ac_command = false;
    var words = answer.split(" ");
    if(words[0] == "пусни" || words[0] == "start") {
      for(let i = 0; i < this.devices.length;i++){
        let contain_count = 0;
        let name_split = this.devices[i].name.split(" ");
        for(let ni = 0; ni < name_split.length; ni++){
          if(words.includes(name_split[ni].toLowerCase())){ contain_count += 1; }
        }
        if(contain_count == name_split.length) { 
          ac_command = true;
          this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";power_on"); 
        }
      }
      if(!ac_command){
        for(let i = 0; i < this.rooms.length;i++){
          let contain_count = 0;
          let name_split = this.rooms[i].name.split(" ");
          for(let ni = 0; ni < name_split.length; ni++){
            if(words.includes(name_split[ni].toLowerCase())){ contain_count += 1; }
          }
          if(contain_count == name_split.length) { 
            let devices_in_room = this.devices.filter(device => device.room == this.rooms[i].uid);
            for(let di = 0; di < devices_in_room.length; di++){
              this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";power_on"); 
            }
          }
        }
      }
    } else if(words[0] == "спри" || words[0] == "stop") {
      for(let i = 0; i < this.devices.length;i++){
        let contain_count = 0;
        let name_split = this.devices[i].name.split(" ");
        for(let ni = 0; ni < name_split.length; ni++){
          if(words.includes(name_split[ni].toLowerCase())){ contain_count += 1; }
        }
        if(contain_count == name_split.length) { 
          ac_command = true;
          this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";power_off"); 
        }
      }
      if(!ac_command){
        for(let i = 0; i < this.rooms.length;i++){
          let contain_count = 0;
          let name_split = this.rooms[i].name.split(" ");
          for(let ni = 0; ni < name_split.length; ni++){
            if(words.includes(name_split[ni].toLowerCase())){ contain_count += 1; }
          }
          if(contain_count == name_split.length) { 
            let devices_in_room = this.devices.filter(device => device.room == this.rooms[i].uid);
            for(let di = 0; di < devices_in_room.length; di++){
              this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";power_off"); 
            }
          }
        }
      }
    } else if(words[0].toLowerCase() == "настрой" || words[0].toLowerCase() == "настройка" || words[0].toLowerCase() == "настройте" || words[0].toLowerCase() == "настройки" || words[0] == "set"){
      let all = false;
      if(words[1] == "всички" || words[1] == "all") { all = true; }
      for(let i = 0; i < this.devices.length;i++){
        let contain_count = 0;
        let name_split = this.devices[i].name.split(" ");
        for(let ni = 0; ni < name_split.length; ni++){
          if(words.includes(name_split[ni].toLowerCase())){ contain_count += 1; }
        }
        if(contain_count == name_split.length || all) { 
          if(words[1 + contain_count] == "на" && words[2 + contain_count] == "режим" || words[1 + contain_count] == "to" && words[2 + contain_count] == "mode" ) {
            if(words[3 + contain_count] == "топло" || words[3 + contain_count] == "heat") {
              this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";set_mode;4");
              this.devices[i].mode_set = "HEAT";
            } else if(words[3 + contain_count] == "студено") {
              this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";set_mode;1");
              this.devices[i].mode_set = "COOL";
            } else if(words[3 + contain_count] == "вентилация") {
              this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";set_mode;3");
              this.devices[i].mode_set = "FAN";
            } else if(words[3 + contain_count] == "изсушаване") {
              this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";set_mode;2");
              this.devices[i].mode_set = "DRY";
            } else if(words[3 + contain_count] == "автоматичен") {
              this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";set_mode;0");
              this.devices[i].mode_set = "AUTO";
            }
          } else if(words[1 + contain_count] == "на" && words[3 + contain_count] == "градуса") { 
            if(Number.isInteger(parseInt(words[2 + contain_count]))){ 
              this.communication.sendMessage("device/" + this.devices[i].serial, this.devices[i].serial + ";set_temperature;"  + (parseInt(words[2 + contain_count])) * 10);
              this.devices[i].temp_set = parseInt(words[2 + contain_count]);
            }
          }
        }
        if(!ac_command){
          for(let i = 0; i < this.rooms.length;i++){
            let contain_count = 0;
            let name_split = this.rooms[i].name.split(" ");
            for(let ni = 0; ni < name_split.length; ni++){
              if(words.includes(name_split[ni].toLowerCase())){ contain_count += 1; }
            }
            if(contain_count == name_split.length) { 
              let devices_in_room = this.devices.filter(device => device.room == this.rooms[i].uid);
              if(words[1 + contain_count] == "на" && words[2 + contain_count] == "режим") {
                for(let di = 0; di < devices_in_room.length; di++){
                  if(words[3 + contain_count] == "топло") {
                    this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";set_mode;4");
                    devices_in_room[di].mode_set = "HEAT";
                  } else if(words[3 + contain_count] == "студено") {
                    this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";set_mode;1");
                    devices_in_room[di].mode_set = "COOL";
                  } else if(words[3 + contain_count] == "вентилация") {
                    this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";set_mode;3");
                    devices_in_room[di].mode_set = "FAN";
                  } else if(words[3 + contain_count] == "изсушаване") {
                    this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";set_mode;2");
                    devices_in_room[di].mode_set = "DRY";
                  } else if(words[3 + contain_count] == "автоматичен") {
                    this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";set_mode;0");
                    devices_in_room[di].mode_set = "AUTO";
                  }
                }
              } else if(words[1 + contain_count] == "на" && words[3 + contain_count] == "градуса") { 
                if(Number.isInteger(parseInt(words[2 + contain_count]))){ 
                  for(let di = 0; di < devices_in_room.length; di++){
                    this.communication.sendMessage("device/" + devices_in_room[di].serial, devices_in_room[di].serial + ";set_temperature;"  + (parseInt(words[2 + contain_count])) * 10);
                    devices_in_room[di].temp_set = parseInt(words[2 + contain_count]);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  setTimeout(()=>{ 
    this.zone.run(() => { this.voiceActive = false; });
  },1500);

}
handleVoiceError(error) { console.log(error); this.voiceActive = false;     this.zone.run(() => {  }); }


Login(email, password, modal){
  this.http.get("api/icon_service.php?action=login&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&e=" + email + "&p=" + sha1(password))
  .subscribe(response => {
    this.logged = response['logged'];
    if(response['logged']) {
      modal.error = "";
     this.phone.user = response['user'];
     this.getDevices();
     this.getRooms();
     this.getSchedules();
     modal.close();
    //  this.communication.setUUID(this.phone.uuid);
    //  this.communication.setUser(this.phone.user);
    //  this.communication.connectUs(this.phone.user);
     setTimeout(()=>{ 
      this.communication.connectUs(this.phone.user);
      // this.stopWait();
     },500);
     if(response['backup']) { this.openModal('force-password', { 'password':'' }); }
    } else {
      setTimeout(()=>{ 
        // this.stopWait();
      },500);
      if(response['error'] == 'N/A') { modal.error = "N/A"; }
      else if(response['error'] == 'Not_verified') { modal.cameFrom = "login"; modal.switchStep('verification'); }
    }
  });
}
Logout(modal) {
  // this.wait(this.languageServ.returnTranslate('label_loader_logout'));
  this.http.get("api/icon_service.php?action=logout&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model)
  .subscribe(response => {
    if(!response['logged']) {
      modal.dismiss();
      this.phone.user = "0";
      this.communication.disconnect();
      this.devices = [];
      this.rooms = [];
      this.schedules = [];
      // setTimeout(()=>{ 
      //   this.stopWait();
      // },500);
      // setTimeout(()=>{ 
      //   this.setPage("login");
      //   this.openLogin();
      // },500);
    }
  });
}

Register(email, password, name, modal) {
  // this.wait(this.languageServ.returnTranslate('label_loader_register'));
  this.http.get("api/icon_service.php?action=register&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&e=" + email + "&p=" + sha1(password) + "&n=" + name)
  .subscribe(response => {
    if(response['done']) {
      // this.stopWait();
      modal.cameFrom = "register";
      modal.switchStep('verification');
    } else {
      // this.stopWait();
      if(response['error'] == 'exists') { modal.errorRegister = 'exists'; }
    }
  });
}

Verify(email, password, code, modal) {
  // this.wait(this.languageServ.returnTranslate('label_loader_verifying'));
  this.http.get("api/icon_service.php?action=verify&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&e=" + email + "&p=" + sha1(password) + "&code=" + code)
  .subscribe(response => {
    if(response['logged']) {
     modal.error = "";
     this.phone.user = response['user'];
     this.getDevices();
     this.getRooms();
     this.getSchedules();
     modal.close();
     this.communication.setUUID(this.phone.uuid);
     this.communication.setUser(this.phone.user);
     this.communication.connectUs(this.phone.user);
    //  this.stopWait();
     modal.errorVerify = ''; 
    } else {
      // this.stopWait();
      if(response['error'] == 'Wrong_verify_code') { modal.errorVerify = 'wrong_code'; }
    }
  });
}

ForgotPassword(email, modal){
  // this.wait(this.languageServ.returnTranslate('label_loader_sending_mail'));
  this.http.get("api/icon_service.php?action=forgot_password&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&e=" + email)
  .subscribe(response => {
    if(response['done']) {
      // this.stopWait();
      modal.forgotError = '';
      modal.switchStep('login');
    } else {
      // this.stopWait();
      if(response['error'] == 'No_user') { modal.forgotError = 'No_user'; }
    }
  });
}

EditDeviceName(){
  this.http.get("api/icon_service.php?action=edit_device_name&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + this.modal.obj.serial + "&name=" + this.modal.obj.name)
  .subscribe(response => {
    if(response['done']) {
      this.getDevices();
      this.closeModal();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
    }
  });
}

RemoveDevice() {
  // this.wait(this.languageServ.returnTranslate('label_removing_device'))
  this.http.get("api/icon_service.php?action=remove_device&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + this.modal.obj.serial)
  .subscribe(response => {
    if(response['done']) {
      this.communication.sendMessage("device/" + this.modal.obj.serial, this.modal.obj.serial + ";power_off"); 
      this.communication.sendMessage("device/" + this.modal.obj.serial, this.modal.obj.serial + ";remove_device");
      this.getDevices();
      this.getSchedules();
      this.closeModal();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
      // setTimeout(()=>{ 
      //   this.stopWait();
      // },500);
    }
  });
}

SilentRemoveDevice(serial, modal) {
  this.http.get("api/icon_service.php?action=remove_device&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + serial)
  .subscribe(response => {
    if(response['done']) {
      this.communication.sendMessage("device/" + this.modal.obj.serial, this.modal.obj.serial + ";remove_device");
      this.getDevices();
      this.getSchedules();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
      modal.dismiss();
    }
  });
}

AddDevice(serial, name, mac, wifi_ssid, wifi_password) {
  this.http.get("api/icon_service.php?action=add_device&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&serial=" + serial + "&name=" + name + "&mac=" + mac + "&wifi_ssid=" + wifi_ssid + "&wifi_password=" + wifi_password)
  .subscribe(response => {
    if(response['done']) {
      this.zone.run(() => { 
        this.getDevices();
        this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      }); 
    }
  });
}

RemoveDeviceFromRoom(serial) {
  this.http.get("api/icon_service.php?action=remove_device_room&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&serial=" + serial)
  .subscribe(response => {
    if(response['done']) {
      let device =  this.devices.filter(device => device.serial == serial)[0];
      let device_schedules = this.schedules.filter(sch => sch.unique == this.devices.filter(device => device.serial == serial)[0].room);
      for(let i = 0; i < device_schedules.length; i++){
        this.communication.sendMessage('device/' + device.serial, device.serial + ";remove_schedule;" + device_schedules[i].uid); 
      }
      this.zone.run(() => { this.devices.filter(device => device.serial == serial)[0].room = 0; }); 
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_rooms");
    }
  });
}

AddDeviceToRoom(serial, room_id, modal) {
  this.http.get("api/icon_service.php?action=add_device_room&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&serial=" + serial + "&room_id=" + room_id)
  .subscribe(response => {
    if(response['done']) {
      let device_schedules = this.schedules.filter(sch => sch.unique == room_id);
      for(let i = 0; i < device_schedules.length; i++){
        this.communication.sendMessage("device/" + serial, serial + ";add_schedule;" + device_schedules[i].uid + "*" + device_schedules[i].timeType + "*1" + "*" + device_schedules[i].time + "*" + device_schedules[i].days + "*" + device_schedules[i].action);
      } 
      this.getDevices();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_rooms");
      modal.closeModal();
    }
  });
}

AddRoom(name, type, devices, modal) {
  this.http.get("api/icon_service.php?action=add_room&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model + "&name=" + name + "&type=" + type + "&devices=" + devices)
  .subscribe(response => {
    if(response['done']) {
      modal.switchStep("4");
      this.getDevices();
      this.getRooms();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_rooms");
    }
  });
}

EditRoomName(){
  this.http.get("api/icon_service.php?action=edit_room_name&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&room_id=" + this.modal.obj.uid + "&name=" + this.modal.obj.name)
  .subscribe(response => {
    if(response['done']) {
      this.getRooms();
      this.closeModal();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_rooms");
    }
  });
}

RemoveRoom() {
  this.http.get("api/icon_service.php?action=remove_room&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&room_id=" + this.modal.obj.uid)
  .subscribe(response => {
    if(response['done']) {
      for(let i = 0; i < this.devices.filter(device => device.room == this.modal.obj.uid).length; i++){
        let device = this.devices.filter(device => device.room == this.modal.obj.uid)[i];
        for(let i = 0; i < response['schedules'].length; i++) { 
          this.communication.sendMessage('device/' + device.serial, device.serial + ";remove_schedule;" + response['schedules'][i].uid); 
        }
      }
      this.getDevices();
      this.getRooms();
      this.getSchedules();
      this.closeModal();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_rooms");
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
    }
  });
}

AddSchedule(scheduleObject, modal) {
  // this.wait(this.languageServ.returnTranslate('label_loader_add_schedule'));
  let scheduleType = 0;
  let timeType = 0;
  let days = "";

  let scheduleAction = "set";
  let fanInt = 0;
  let modeInt = 0;
  let action = "";
  if(scheduleObject.type == 'device') { scheduleType = 1; } else { scheduleType = 2; }
  if(scheduleObject.scheduleType == 'timer') { timeType = 1; } else { timeType = 2; }
  for(let i = 0; i < 7; i++) { days += "" + scheduleObject.days[i]; }

  if(scheduleObject.fan_speed == 1) { fanInt = 2; } else if(scheduleObject.fan_speed == 2) { fanInt = 5; }
  else if(scheduleObject.fan_speed == 3) { fanInt = 8; } else if(scheduleObject.fan_speed == 4) { fanInt = 11; }
  else if(scheduleObject.fan_speed == 5) { fanInt = 0; } 

  if(scheduleObject.mode == 'auto') { modeInt = 0; } else if(scheduleObject.mode == 'cool') { modeInt = 1; }
  else if(scheduleObject.mode == 'dry') { modeInt = 2; } else if(scheduleObject.mode == 'fan') { modeInt = 3; } 
  else if(scheduleObject.mode == 'heat') { modeInt = 4; }

  if(scheduleObject.action == 'OFF') { scheduleAction = 'off' } else { scheduleAction = 'set' }
  action = scheduleAction + "*" + scheduleObject.temp + "*" + modeInt + "*" + fanInt;
  this.http.get("api/icon_service.php?action=add_schedule&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&selectType=" + scheduleType + "&unique=" + scheduleObject.unique + "&timeType=" + timeType + "&scheduleAction=" + action + "&time=" + scheduleObject.time + "&days=" + days)
  .subscribe(response => {
    if(response['done']) {
      if(scheduleObject.type == 'device'){
        this.communication.sendMessage("device/" + scheduleObject.unique, scheduleObject.unique + ";add_schedule;" + response['uid'] + "*" + timeType + "*1" + "*" + scheduleObject.time + "*" + days + "*" + action); 
        if(this.devices.filter(device => device.serial == scheduleObject.unique).length > 0) {
          if(this.devices.filter(device => device.serial == scheduleObject.unique)[0].shared) {
            this.communication.sendMessage('phone-user/' + this.devices.filter(device => device.serial == scheduleObject.unique)[0].user, this.phone.uuid + ";refresh_schedules");
          } else {
            if(this.devices.filter(device => device.serial == scheduleObject.unique)[0].shares != undefined) {
              for(let i = 0; i < this.devices.filter(device => device.serial == scheduleObject.unique)[0].shares.length; i++){
                this.communication.sendMessage('phone-user/' + this.devices.filter(device => device.serial == scheduleObject.unique)[0].shares[i].shared_to, this.phone.uuid + ";refresh_schedules");
              }
            }
          }
        }
      } else {
        for(let i = 0; i < this.returnRoomDevicesByUID(scheduleObject.unique).length; i++) {
          this.communication.sendMessage("device/" + this.returnRoomDevicesByUID(scheduleObject.unique)[i].serial, this.returnRoomDevicesByUID(scheduleObject.unique)[i].serial + ";add_schedule;" + response['uid'] + "*" + timeType + "*1" + "*" + scheduleObject.time + "*" + days + "*" + action); 
        }
      }
      this.getSchedules();
      // setTimeout(()=>{ 
      //   this.stopWait();
      // },500);
      modal.dismiss();
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
    }
  });
}

EditSchedule(schedule, days, modal) {
  // this.wait("Променяне на график...");
  let action = "";
  action += schedule.action + "*" + schedule.temp + "*" + schedule.mode + "*" + schedule.fan_speed;
  let found_schedule = this.schedules.filter(sch => sch.uid == schedule.uid);
  let schedule_to_add = schedule.uid + "*" + found_schedule[0].timeType + "*" + found_schedule[0].selectType + "*" + schedule.time + "*" + days + "*" + action;
  this.http.get("api/icon_service.php?action=edit_schedule&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&uid=" + schedule.uid + "&time=" + schedule.time + "&days=" + days + "&schedule_action=" + action)
  .subscribe(response => {
    if(found_schedule[0].selectType == "1") {
      this.communication.sendMessage("device/" + schedule.unique, schedule.unique + ";remove_schedule;" + schedule.uid);
      this.communication.sendMessage("device/" + schedule.unique, schedule.unique + ";add_schedule;" + schedule_to_add);
    } else{
      for(let i = 0; i < this.returnRoomDevicesByUID(schedule.unique).length; i++) {
        let device = this.returnRoomDevicesByUID(schedule.unique)[i]
        this.communication.sendMessage("device/" + device.serial, device.serial + ";remove_schedule;" + schedule.uid);
        this.communication.sendMessage("device/" + device.serial, device.serial + ";add_schedule;" + schedule_to_add);
      }
    }
    this.getSchedules();
    this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
    // setTimeout(()=>{ 
    //   this.stopWait();
    // },500);
    modal.dismiss();
  });
}

RemoveSchedule(schedule, modal) {
  // this.wait(this.languageServ.returnTranslate('label_loader_remove_schedule'));
  this.http.get("api/icon_service.php?action=remove_schedule&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&uid=" + schedule.uid)
  .subscribe(response => {
    if(response['done']) {
      // setTimeout(()=>{ 
      //   this.stopWait();
      // },500);
      if(schedule.selectType == 1){
        this.communication.sendMessage("device/" + schedule.unique, schedule.unique + ";remove_schedule;" + schedule.uid); 
        if(this.devices.filter(device => device.serial == schedule.unique).length > 0) {
          if(this.devices.filter(device => device.serial == schedule.unique)[0].shared) {
            this.communication.sendMessage('phone-user/' + this.devices.filter(device => device.serial == schedule.unique)[0].user, this.phone.uuid + ";refresh_schedules");
          } else {
            if(this.devices.filter(device => device.serial == schedule.unique)[0].shares != undefined) {
              for(let i = 0; i < this.devices.filter(device => device.serial == schedule.unique)[0].shares.length; i++){
                this.communication.sendMessage('phone-user/' + this.devices.filter(device => device.serial == schedule.unique)[0].shares[i].shared_to, this.phone.uuid + ";refresh_schedules");
              }
            }
          }
        }
      } else {
        for(let i = 0; i < this.returnRoomDevicesByUID(schedule.unique).length; i++) {
          this.communication.sendMessage("device/" + this.returnRoomDevicesByUID(schedule.unique)[i].serial, this.returnRoomDevicesByUID(schedule.unique)[i].serial + ";remove_schedule;" + schedule.uid); 
        }
      }
      this.getSchedules();
      modal.total -= 1
      modal.closeModal();
      if(modal.total == 0) { modal.dismiss(); }
      this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
    }
  });
}

SwitchScheduleActive(schedule_id){
  let schedule = this.schedules.filter(schedule_arr => schedule_arr.uid == schedule_id);
  if(schedule.length > 0) {
    let active = 0;
    if(schedule[0].active == 1) { active = 0; } else { active = 1; }
    this.http.get("api/icon_service.php?action=switch_schedule_active&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&uid=" + schedule[0].uid + "&active=" + active)
    .subscribe(response => {
      if(response['done']) {
        schedule[0].active = active;
        if(schedule[0].selectType == 1) {
          this.communication.sendMessage('device/' + schedule[0].unique, schedule[0].unique + ";switch_schedule_active;" + schedule[0].uid + ";" + active );
        } else {

        }
        this.communication.sendMessage('phone-user/' + this.phone.user, this.phone.uuid + ";refresh_schedules");
      }
    });
  }
}

ChangePassword() {
  if(this.modal.obj.old_password.length > 0 && this.modal.obj.new_password.length > 0) {
    this.http.get("api/icon_service.php?action=change_password&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&old_password=" + sha1(this.modal.obj.old_password) + "&new_password=" + sha1(this.modal.obj.new_password))
    .subscribe(response => {
      if(response['done']) {
        this.closeModal();
      } else {
        if(response['error'] == 'invalid_password') { this.modal.obj.error = "invalid_password"; }
      }
    });
  } else {
    this.modal.obj.error = 'not_filled';
  }
}

ForceChangePassword() {
  if(this.modal.obj.password.length > 3){
    this.http.get("api/icon_service.php?action=force_password&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&password=" + sha1(this.modal.obj.password))
    .subscribe(response => {
      if(response['done']) {
        this.closeModal();
      }
    });
  } else {
    this.modal.obj.error = 'not_filled';
  }
}

ChangeName() {
  if(this.modal.obj.name.length > 0) {
    this.http.get("api/icon_service.php?action=change_name&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&name=" + this.modal.obj.name)
    .subscribe(response => {
      if(response['done']) {
        this.phone.name = this.modal.obj.name;
        this.closeModal();
      }
    });
  } else {
    this.modal.obj.error = 'not_filled';
  }
}

ShareDevice(serial, email, modal){
  this.http.get("api/icon_service.php?action=share_device&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + serial + "&email=" + email)
  .subscribe(response => {
    if(response['done']) {
      this.getDevices();
      this.communication.sendMessage('phone-user/' + response['shared_to'], this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + response['shared_to'], this.phone.uuid + ";refresh_schedules");
      modal.closeModal();
    } else {
      modal.modal.obj.error = response['error'];
    }
  });
}

RemoveSharedDevice(serial, user){
  // this.wait(this.languageServ.returnTranslate('label_loader_removing_shared'));
  this.http.get("api/icon_service.php?action=remove_share&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + serial)
  .subscribe(response => {
    if(response['done']) {
      // setTimeout(()=>{ 
      //   this.stopWait();
      // },500);
      this.getDevices();
      this.communication.sendMessage('phone-user/' + user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + user, this.phone.uuid + ";refresh_schedules");
      this.closeModal();
    }
  });
}

RemoveShare(serial, email, user) {
  // this.wait(this.languageServ.returnTranslate('label_loader_removing_share'));
  this.http.get("api/icon_service.php?action=remove_shared&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + serial + "&email=" + email)
  .subscribe(response => {
    // setTimeout(()=>{ 
    //   this.stopWait();
    // },500);
    if(response['done']) {
      this.getDevices();
      this.communication.sendMessage('phone-user/' + user, this.phone.uuid + ";refresh_devices");
      this.communication.sendMessage('phone-user/' + user, this.phone.uuid + ";refresh_schedules");
    }
  });
}

ChangeNetwork(serial, network) {
  for(let i = 0; i < this.devices.length; i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].wifi_ssid = network;
      this.http.get("api/icon_service.php?action=set_new_wifi&uuid=" + this.phone.uuid + "&platform=" + this.phone.platform + "&platform_version=" + this.phone.platform_version + "&software=" + this.software + "&model=" + this.phone.model +  "&serial=" + serial + "&network=" + network)
      .subscribe(response => {
      });
    }
  }
}

ChangeMagneticSettings(serial, activation, mode, activation_time, activation2, mode2, activation_time2) {
  for(let i = 0; i < this.devices.length; i++){
    if(this.devices[i].serial == serial) {
      this.devices[i].input1_activation = activation;
      this.devices[i].input1_mode = mode;
      this.devices[i].input1_activation_time = activation_time;
      this.devices[i].input2_activation = activation2;
      this.devices[i].input2_mode = mode2;
      this.devices[i].input2_activation_time = activation_time2;
    }
  }
}

setupCommunication(communication) { this.communication = communication; }
setPage(page){ this.page = page; }

checkDevicesActive() {
  let dateNow;
  dateNow = new Date();
  for(let i = 0; i < this.devices.length; i++){
    var timeDiff = dateNow - this.devices[i].last_active; //in ms
    var timeCommDiff = dateNow - this.devices[i].last_communicated;
    timeDiff /= 1000;
    timeCommDiff /= 1000;
    var seconds = Math.round(timeDiff);
    var secondsComm = Math.round(timeCommDiff);
    if(seconds > 10) { this.devices[i].active = false; this.devices[i].initialized = true; }
    if(secondsComm > 10) { this.devices[i].communicated = false;  this.devices[i].initialized = true;  }
  }
  this.zone.run(() => {  });
}

openModal(cntx, obj){ 
  if(cntx == 'edit-device-name') { this.lastObject = JSON.parse(JSON.stringify(obj)); }
  this.modal = { open:true, 'cntx':cntx, 'obj':obj }; 
}
closeModal(){ 
  if(this.modal.cntx != 'no-internet') {
    this.modal.open = false; 
  }
}

async presentEditRoom(){
  // this.closeModal();
  // this.setPage("edit-room");
  // const modal = await this.modalController.create({
  //   component: EditRoomModalPage,
  //   componentProps: {
  //     'deviceServ': this
  //   }
  // });
  // modal.onDidDismiss().then((data) => {
  //   this.setPage("");
  // });
  // return await modal.present();
}

async presentShareDevice(serial) {
  // this.closeModal();
  // this.setPage("share-device");
  // const modal = await this.modalController.create({
  //   component: ShareDeviceModalPage,
  //   componentProps: {
  //     'deviceServ': this,
  //     'serial':serial
  //   }
  // });
  // modal.onDidDismiss().then((data) => {
  //   this.setPage("");
  // });
  // return await modal.present();
}

async presentViewDevice(serial) {
  // this.closeModal();
  // this.setPage("view-device");
  // const modal = await this.modalController.create({
  //   component: DeviceDetailsModalPage,
  //   componentProps: {
  //     'serial':serial
  //   }
  // });
  // modal.onDidDismiss().then((data) => {
  //   this.setPage("");
  // });
  // return await modal.present();
}

IfPossibleCloseModal() {
  if(this.modal.cntx != 'force-password') {
    this.closeModal()
  }
}

setPhoneWidth(width) { this.width = width; }
switchGridActive() {
  this.gridActive = !this.gridActive;
  // this.nativeStorage.setItem('grid', this.gridActive);
}
switchGridNumber(number) {
  this.gridNumber = number;
  // this.nativeStorage.setItem('grid_num', this.gridNumber);
}

isIphoneX = () => {
  try {
      const iphoneModel = this.phone.model;
      const m = iphoneModel.match(/iPhone(\d+),?(\d+)?/);
      const model = +m[1];
      if (model >= 10) { // is iphone X
          return true;
      }
  } catch (e) { }
  return false;
}

returnUser() { return this.phone.user; }
returnEmail() { return this.phone.email; }
returnName() { return this.phone.name; }

returnProtocolName(num){ return this.protocol_classifier.filter(protocol => protocol.num == num)[0].name; }
returnProtocols() { return this.protocol_classifier; };

returnPage() { return this.page; }
returnVoiceActive() { return this.voiceActive; }

returnDevice(serial) { return this.devices.filter(device => device.serial == serial)[0]; }
returnDevices() { return this.devices; }
returnUnassignedDevices() { return this.unassigned_devices; }

returnRoom(room_id) { return this.rooms.filter(room => room.uid == room_id)[0]; }
returnRooms(){ return this.rooms; }
returnRoomDevicesByUID(uid) { return this.devices.filter(device => device.room == uid); }
returnRoomDevices(room) { return this.devices.filter(device => device.room == room.uid); }
returnRoomActiveDevices(room) { return this.devices.filter(device => device.room == room.uid && device.power_set == 'ON' || device.room == room.uid && device.power == 'ON' && device.power_set == ''); }
returnDevicesFromRoom(room_uid) { return this.devices.filter(device => device.room == room_uid); }
returnRoomlessDevices() { return this.devices.filter(device => device.room == 0); }

returnSoftwareVersion() { return this.software; }
returnSoftwareDate() { return this.last_update_software; }

returnSchedules() { return this.schedules; }
returnFactoredSchedules() { return this.factoredSchedules; }

returnPhoneWidth() { return this.width; }
returnGrid() { return this.gridActive; }
returnGridNumber() { return this.gridNumber; }

returnLogged() {
  return this.logged;
}
}
